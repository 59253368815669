// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "../_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "../_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "../_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "../_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "../_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "../_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "../_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

// Custom styles

@import "~react-datepicker/dist/react-datepicker.css";

.react-datepicker-wrapper {
  display: block;
}

.invalid-datepicker-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.9rem;
  color: #f64e60;
  font-weight: 400;
}

.wrapper {
  padding-top: 80px !important;
}

.full-height {
  min-height: 100vh;
}

.main-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div:first-child {
    display: flex;
  }

  .main-logo {
    display: flex;
    align-items: center;
  }
}

.map-img {
  max-height: 300px;
  width: 100%;
}

.form .feedback {
  display: none;
}

// Form control
.form-control {
  // Readonly state
  &[readonly] {
    background-color: $gray-100;
  }
}

.file-uploader-container {
  outline: none;
}

.file-uploader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  padding: 10px;
  border: 1px solid #3f4254;

  img {
    width: 80px;
    height: auto;
    max-height: 100px !important;
  }
}

.pac-container:after {
  content: none !important;
}

.pac-container:after {
  display: none !important;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.download-icon {
  padding-right: 0 !important;
}

.files-item {
  padding: 10px 20px;
  background-color: #f3f6f9;
  border-radius: 1rem;
  display: relative;
}

.file-title {
  margin-bottom: 0;
}

.file-date {
  margin-left: 15px;
}

.card-toolbar {
  min-width: 300px;
  display: flex;
  justify-content: space-between;
}

.upload-popup .MuiDialog-paper {
  min-width: 350px;
}

.custom-checkbox .checkbox {
  display: inline-block;
  margin-right: 15px;
}

.checkbox-form-control {
  display: flex;
  align-items: center;

  .checkbox {
    padding-left: 0;
    margin-left: 0;
  }

  > label {
    margin: 0;
    margin-left: 15px;
  }
}

.custom-alert .MuiAlert-message {
  padding: 10px ​0 8p;
}

.toolbar-search .MuiPaper-rounded {
  height: 32px !important;
  width: 200px;
}

.map-container {
  height: 300px;
}

@media (max-width: 990px) {
  .site-map-location {
    display: none;
  }
}

.no-svg {
  svg {
    display: none !important;
  }
}

.MuiTabs-indicator {
  background-color: #17c191 !important;
}

.Mui-selected.MuiTab-labelIcon {
  span {
    color: #17c191;
  }

  svg {
    fill: #17c191;
  }
}

.MuiTab-labelIcon {
  span {
    color: #fff;
  }

  svg {
    fill: #fff;
  }
}

.contact-list {
  max-width: 800px;
  margin: 0 auto;

  .row {
    background-color: #f3f6f9;
    border-bottom: 1px solid #1e1e2d;

    select {
      border: none;
      background-color: inherit;
    }
  }
}

.MuiTabs-flexContainer {
  background-color: #1e1e2d;
}

.valid-feedback {
  display: none !important;
}

.is-invalid {
  border-color: #f64e60 !important;
}

.MuiTab-labelIcon .MuiTab-wrapper .la {
  font-size: 1.7rem !important;
}

.qr-image {
  display: block;
  width: 100%;
  max-width: 250px;
  height: auto;
  margin: 20px auto 0;
}

.contacts-table input {
  background-color: transparent;
  border: none;
  outline: none;
}

.print-wrap {
  padding: 20px 0;
  text-align: center;
}

.contacts-table .error {
  border-bottom: 1px solid red;
}

.edit-contact {
  cursor: pointer;
}

.create-contact-wrap {
  text-align: center;
  padding-bottom: 30px;
}

.tooltip .tooltip-inner {
  max-width: 30em;
  margin: 0 auto;
}

.test-progress-bar {
  width: 30em;
  margin-right: 2em;
  margin-bottom: 2.5em;
  .one,
  .two,
  .three,
  .four {
    position: absolute;
    z-index: 1;
    height: 80px;
    width: 30%;
    margin-top: -11px;
    transform: translate(-50%, -30px);
    left: -50%;
  }
  .circle {
    position: relative;
    height: 20px;
    width: 20px;
    border-radius: 25px;
    transform: translate(-50%, 33px);
    left: 50%;
  }
  .above,
  .below {
    position: absolute;
    line-height: initial;
    text-align: center;
    left: 50%;
  }
  .above {
    width: 10em;
    top: 30px;
    transform: translate(-50%, -100%);
  }
  .below {
    width: 7em;
    transform: translateX(-50%);
    top: 6em;
  }
  .one {
    left: 20%;
  }
  .two {
    left: 47%;
  }
  .three {
    left: 75%;
  }
  .four {
    left: 99%;
  }
  .bg-primary {
    background-color: #007bff;
  }
  .bg-sucess {
    background-color: #28a745;
  }
  .no-color {
    background-color: #e9ecef;
  }
  .progress {
    height: 0.5rem;
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .progress-bar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    transition: width 0.6s ease;

    &.width-20 {
      width: 20%;
    }

    &.width-40 {
      width: 47%;
    }

    &.width-85 {
      width: 75%;
    }

    &.width-100 {
      width: 100%;
    }
  }
  /* Progress Bar CSS */
}
